.man {
    display: flex;
    height: 100%;
}
.man > .toc {
    width: clamp( 200px, 15%, 45% );
    position: sticky;
    top: 0;
}

.man > .toc li{
    list-style: circle;
}
.man > .toc li:has(a.active){
    list-style: disc;
}
.man > .toc a.active {
    color: black;
    text-decoration: none;
}

.man > .md {
    width: clamp( 800px, 100%, 1000px );
    margin: auto;
    padding: .7em;
    height: calc( 100% - 1.5em );
    overflow: auto;
}

/* .man > .toc li:last-of-type{
    display: none
} */


@media (max-width: 800px) {
    .man > .md {
        width: calc( 100% - 1.5em );
    }
}

@media (max-width: 600px) {
    .man {
        flex-direction: column;
    }
    .man > .md {
        width: calc( 100% - .6em );
        padding: .3em;
    }
}