:root {
    --categories-width: 23%;
}

#categories {
    flex: 0 0 var(--categories-width);
    max-width: var(--categories-width);
    min-width: var(--categories-width);
    width: var(--categories-width);
}
