:root {
    --header-height: 3.3em;
}
#header {
    height: var( --header-height );
    display: flex;
    align-items: start;
    gap: 2em;
}
#header p {
    align-self: center;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
#header p > * {
    align-self: center;
    line-height: initial;
}
#header > * {
    margin: 0; padding: 0;
    max-height: var( --header-height );
}

#header * {
    max-height: var( --header-height );
}


#header a > img {
    max-height: calc( var( --header-height ) - 1em );
}


@media (max-width: 800px) {
    #header span.ant-tabs-tab-icon + span{
        display: none;
    }
}
@media (max-width: 600px) {
    #header p span.ant-avatar + span{
        display: none;
    }
}
