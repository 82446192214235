.highLight {
    position: relative;
    min-height: 3em;
    background: ghostwhite;
    height: 100%;
    width: 100%;
}

.highLight > * {
    position: absolute;
    top: 0;
    left: 0;
    width: calc( 100% - .6em );
    height: calc( 100% - .6em );
    margin: 0;
    padding: .3em;

    font-size: 1.2em;
    border: thin solid transparent;
    border-radius: .3em;

    overflow: auto;
}

.highLight > .highLight_editable {
    color: transparent;
    caret-color: darkslategrey;
    caret-shape: underscore;
    border-color: #60a3d8 #2970a9 #2970a9 #60a3d8;
}
.highLight > .highLight_editable:focus {
    outline: 1px solid hsl(220, 16%, 19%);
}
.highLight > .highLight_editable::selection, .highLight > .highLight_editable *::selection {
    color: whitesmoke;
    background: darkslategrey;
}

.highLight > .highLight_colors {
    user-select: none;
    background: floralwhite;
}
.highLight > .highLight_colors i {
    font-style: normal;
}

/* JS */
.highLight > .highLight_colors i.js_quot { color: darkmagenta; }
.highLight > .highLight_colors i.js_decl { color: #79C6ECFF; }
.highLight > .highLight_colors i.js_func { color: royalblue; }
.highLight > .highLight_colors i.js_pare { color: #79B3ECFF; }
.highLight > .highLight_colors i.js_squa { color: #798CECFF; }
.highLight > .highLight_colors i.js_curl { color: #8C79ECFF; }
.highLight > .highLight_colors i.js_numb { color: #9FEC79FF; }
.highLight > .highLight_colors i.js_logi { color: #79C6ECFF; }
.highLight > .highLight_colors i.js_equa { color: #79C6ECFF; }
.highLight > .highLight_colors i.js_comm { color: #678; font-style: italic; }
.highLight > .highLight_colors i.js_comm  * { color: inherit; font-style: italic; }

/* XML */
.highLight > .highLight_colors i.xml_head { color: mediumseagreen; font-style: italic; }
.highLight > .highLight_colors i.xml_angl { color: darkslateblue; }
.highLight > .highLight_colors i.xml_tags { color: royalblue; }
.highLight > .highLight_colors i.xml_attr { color: darkolivegreen; }
.highLight > .highLight_colors i.xml_quot { color: darkmagenta; }
.highLight > .highLight_colors i.xml_comm { color: #678; font-style: italic; }
.highLight > .highLight_colors i.xml_comm * { color: inherit; font-style: italic; }
.highLight > .highLight_colors i.xml_comm_be { color: #ABF; }
.highLight > .highLight_colors i.xml_comm_en { color: #ABF; }


.highLight > .highLight_colors,
.highLight > .highLight_editable,
.highLight > .highLight_colors * ,
.highLight > .highLight_editable * {
    white-space: pre;
    font-family: monospace;
}

.highLight > .tfs {
    overflow: visible;
    position: absolute;
    top: -.1em;
    width: 1em; height: 1em;
    left: calc( 100% - 1.5em );
    text-align: right;
    opacity: .5;
    cursor: pointer;
}
.highLight > .tfs:hover {
    opacity: 1;
}
.highLight > .tfs-expand {
    display: block;
    cursor: zoom-in;
}
.highLight > .tfs-compress {
    display: none;
    cursor: zoom-out;
}

.highLight.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    margin: .3em;
    padding: .3em;

    min-height: auto;
    max-height: calc( 100% - 1.3em );

    width: calc( 100% - 1.3em );
}
.highLight.fullScreen > .tfs-expand {
    display: none;
}
.highLight.fullScreen > .tfs-compress {
    display: block;
}
