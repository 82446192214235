.md  ul, .md  ol {
    padding-left: .7em;
    margin-block-start: .3em;
    margin-block-end: .3em;
}
.md  ol { list-style: auto; }
.md  ul {             list-style: disc;}
.md  ul ul {          list-style: circle;}
.md  ul ul ul {       list-style: square;}
/* .md  ul ul ul ul {    list-style: ;} */

.md  ul li, .md  ol li {
    margin-left: 1em;
}
.md  h1 {
    text-align: center;
    font-weight: bolder;
    font-size: 1.7em;
}
.md  h2 {
    font-size: 1.7em;
    margin-left: 2em;
}
.md  h3 {
    margin-left: 3em;
}
.md  h4 {
    margin-left: 4em;
}
.md  h5 {
    margin-left: 5em;
}

.md  b, .md  strong{
    font-weight: bolder;
}
.md  em{
    font-style: oblique;
}
.md  blockquote{
    border-left: thick solid lightgray;
    padding: .3em;
}
.md  pre {
    font-family: monospace;
}
.md  code {
    background: linear-gradient(-30deg, lightyellow, transparent);
    border-bottom: thin dotted;
    font-size: .9em;
}
.md  pre code {
    display: inline-block;
    border: thin dotted;
    padding: .1em .3em;
    border-radius: .2em;
    font-size: .7em;
}
.md  table {
    border-collapse: collapse;
}
.md  table th, .md  table td {
    border: thin solid black;
    padding: 0 .3em;
}
.md  table th {
    font-weight: bolder;
    text-align: center;
}
.md  ins {
    color: forestgreen;
    font-family: monospace;
}
.md  del {
    color: firebrick;
    font-family: monospace;
}
/* .md  dl */
.md  dl > dt {
    text-decoration: underline;
}
.md  dl > dd {
    border-left: thin dotted;
    font-size: .8em;
    margin-left: .3em;
    padding-left: .3em;
}
.md  p {
    margin-bottom: .3em;
    margin-block-start: .7em;
    margin-block-end: 0;
}

.md img {
    max-width: calc( 100% - 1.5em );
}
