.promo {
    display: flex;
    height: 100%;
}
.promo > .toc {
    width: clamp( 200px, 15%, 45% );
    position: sticky;
    top: 0;
}

.promo > .toc li{
    list-style: circle;
}
.promo > .toc li:has(a.active){
    list-style: disc;
}
.promo > .toc a.active {
    color: black;
    text-decoration: none;
}

.promo > .content {
    width: clamp( 320px, 100%, 1000px );
    height: calc( 100% - 2em );
    margin: auto;
    padding: .7em;
    overflow: auto;
    /* height: clamp(320px, 100%, 720px); */
}

.promo > .content fieldset {
    height: calc( 100% - 2em );
}

embed {
    width: 100%;
    height: 100%;
}

@media (max-width: 720px) {
    .promo {
        flex-direction: column;
    }
}