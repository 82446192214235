#footer {
    height: 1em;
    margin: 0;
    padding: .1em;
    overflow: hidden;
    text-align: right;
    line-height: .9em;
}

#footer * {
    font-size: .9em;
    height: 1em;
    padding: 0;
    color: lightsteelblue;
    display: inline-block;
    margin: 0 0 0 1em;
}
