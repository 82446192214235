:root{
    --gnzdia-bg-color: lightsteelblue;
}
*:has(>.gnzdia) {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: #AAA5;
    z-index: 1010;
    animation: backdrop-fade 2s ease forwards;
}
.gnzdia {
    overflow: hidden;
    width: min( 100vw , 47em );
    margin: auto;

    border: thick solid var( --gnzdia-bg-color );
    border-radius: .3em;
    box-shadow: 0 0 .1em;

    padding: 0;
    display: flex;
    flex-direction: column;

    position: fixed;
    z-index: 1020;

    top: 7vh;
    left: calc( ( 100vw - min( 100vw, 47em ) ) / 2 );

    background-color: #FFF;
}

.gnzdia:hover{
    box-shadow: 0 0 .3em;
}

.gnzdia > * {
    margin: 0;
    padding: .8em;
    user-select: none;
}
.gnzdia > h3 {
    padding: .1em 1.7em .1em .3em;
    width: 100%;
    color: white;
    margin: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: thin solid var( --gnzdia-bg-color );
    border-bottom: thin solid var( --gnzdia-bg-color );
    box-shadow: 0 0 .73em var( --gnzdia-bg-color );
    background-color: var( --gnzdia-bg-color );
    cursor: pointer;
    z-index: 9;
}

.gnzdia > h3 ~*:not(.buttons) {
    overflow: auto;
}

.gnzdia .buttons {
    padding: .4em 0 .1em 0;
    color: white;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: thin solid var( --gnzdia-bg-color );
    border-bottom: thin solid var( --gnzdia-bg-color );
    box-shadow: 0 0 .73em var( --gnzdia-bg-color );
    background-color: var( --gnzdia-bg-color );
    z-index: 9;
    display: grid;
    grid-template-areas: "SUBMIT . . . CLOSE";
}
.gnzdia .buttons .submit { grid-area: SUBMIT; width: min-content; left: 0; color: #2970a9}
.gnzdia .buttons .close  { grid-area: CLOSE; width: min-content; position:absolute; right:0; color: saddlebrown}

.gnzdia fieldset > input[type="text"],
.gnzdia fieldset > textarea{
    width: calc( 100% - .3em );
}
/*
.gnzdia fieldset > textarea{
    min-height: 7em;
    max-height: 70vh;
}
*/

.gnzdia > pre {
    white-space: break-spaces;
    font-family: monospace;
    font-size: .8em;
}

.gnzdia[open]::backdrop {
    animation: backdrop-fade 2s  ease forwards;
}

.gnzdia > h3 ~ .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.4em;
    overflow: visible;
    z-index: 99;
    /* text-shadow: .03em .03em .07em darkslateblue; */
    text-align: center;
    /* text-indent: .2em; */
    display: block !important;
    width: 1em;
    padding: 0;
    margin: 0;
    color: ghostwhite;
    transition: all .3s;
}
.gnzdia > h3 ~ .close * {
    padding: 0;
    margin: 0;
}
.gnzdia > h3 ~ .close:hover {
    text-shadow: 0 0 .2em white;
    background: lightcoral;
    border-radius: .1em;
}

.gnzdia  form fieldset {
    border: thin solid #AAAA;
    border-radius: .3em;
    position: relative;
}
.gnzdia  form fieldset > legend{
    border-bottom-color: transparent !important;
    display: inline !important;
    padding: 0 .3em 0 .3em !important;
}
@keyframes backdrop-fade {
    from {
        background: transparent;
        backdrop-filter: blur(0);
    }
    /* 50% { backdrop-filter: blur(.7em); } */
    to{
        background: #AAA5;
        backdrop-filter: blur(.13em);
    }
}


@media (max-width: 800px) {
    .gnzdia {
        max-width: 90% !important;
    }
}

@media (max-width: 600px) {
    .gnzdia {
        max-width: calc( 100% - 1em ) !important;
    }
}
