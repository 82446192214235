
.tabs {
    display: flex;
    flex-direction: column;
    padding: 0;
}
.tabs > * {
    padding: .7em .7em 0;
}
.tabs > .tablist {
    display: flex;
    flex-direction: row;
    gap: .7em;
    padding-top: 0;
    background: #b0c4de;
}
.tabs > .tabpanel {
    border: thin solid;
    border-color: #60a3d8 transparent transparent #60a3d8;
}
.tabs > .tabpanel--hidden {
    display: none;
}
.tabs > .tablist > .tab {
    color: white;
    background: linear-gradient(#89bbe2, transparent);
    border-radius: .2em .2em 0 0;
    border-color: #60a3d8 #2970a9 transparent #60a3d8;
    border-bottom: none;
}
.tabs > .tablist > .tab:hover {
    color: white;
    background: linear-gradient(#89bbe2, transparent);
    box-shadow: none;
}
.tabs > .tablist > .tab[aria-selected="true"] {
    /* color: white;
    background: linear-gradient(#89bbe2, #60a3d8 50%, white);
    */
    color: #2970a9;
    background: white;
    border-bottom: white;
    margin-bottom: -.07em;
    z-index: 2;
    box-shadow: none;
}

.tabpanel--hidden {
    display: none;
}

.template-editor {width: clamp(600px, 100%, 800px);}

.template-editor form .tabpanel{
    min-height: 41vh;
}
.template-editor form .tabpanel * {
    margin: 0;
}
.template-editor form .EditorForm-tabpanel-main:not(.tabpanel--hidden) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-areas: "CODE NAME NAME NAME"
                         "CATE KEYW KEYW KEYW"
                         "DESC DESC DESC DESC"
                         "CACH FILE FILE FILE"
                         "  .    .   .    .  "
    ;
    gap: .3em;
}

.template-editor form .template-code{ grid-area: CODE; }
.template-editor form .template-title{ grid-area: NAME; }

.template-editor form .template-category{ grid-area: CATE; }
.template-editor form .template-keywords{ grid-area: KEYW; }

.template-editor form .template-description{ grid-area: DESC; }
.template-editor form .template-description textarea {
    height: 3em;
}

.template-editor form .template-example{
    grid-area: EXAM;
    display: flex;
    flex-direction: column;
    height: 41vh;
}
.template-editor form .template-form{
    grid-area: FORM;
    display: flex;
    flex-direction: column;
    height: 41vh;
}
.template-editor form .template-generator{
    grid-area: GENE;
    display: flex;
    flex-direction: column;
    height: 41vh;
}

.template-editor form .template-cacheMaxAge{ grid-area: CACH; }
.template-editor form .template-tfile{
    grid-area: FILE;
    display: flex;
    justify-content: space-between;
}
.template-editor form .template-tfile .download {
    align-self: center;
    text-align: center;
}

.template-cacheMaxAge > p {
    display: flex;
}
.template-cacheMaxAge  label {
    display: block;
}
.template-cacheMaxAge  label input{
    display: block;
}
.template-cacheMaxAge  label:after{
    content: "";
}
.template-cacheMaxAge  label > input[type="number"] {
    font-size: 1.2em;
    width: 5em;
}

.template-cacheMaxAge label {
    justify-content: center;
    justify-items: center;
    margin: auto;
}
.template-cacheMaxAge label > * {
    margin: auto;
}


@media (max-width: 600px){
    .template-editor {width: 100%;}
}
