/* see: appServer/src/commonMain/resources/static/4form/css.css */
.gnzdia.formContainer.withDetails details{
    padding: .7em;
}
.gnzdia.formContainer.withDetails details > div {
    display: grid;
    grid-auto-flow: dense;
    gap: .7em;
    padding: 0;
    margin: 0;
    grid-template-areas:
    "TFILE TFILE TFILE TFILE"
    "DATA  DATA  DATA  DATA "
    " FN    FN    FN    FF  "
    " CB    CB    CB    CB  "
    ;
}

.gnzdia.formContainer.withDetails details > div > * {
    width: calc( 100% - .6em );
    margin: 0;
    padding: .3em;
}

.gnzdia.formContainer.withDetails details > div .data {
    grid-area: DATA;
    display: flex;
    flex-direction: column;
    height: 50vh
}

.gnzdia.formContainer.withDetails details > div .fn {
    grid-area: FN;
}

.gnzdia.formContainer.withDetails details > div .ff {
    grid-area: FF;
}

.gnzdia.formContainer.withDetails details > div .cb {
    grid-area: CB;
}

.gnzdia.formContainer.withDetails details > div .tfile {
    grid-area: TFILE;
}

.gnzdia.formContainer > * h3 { display: none}

/*
.gnzdia.formContainer > h3::before{
    font-family: var( --fa-style-family );
    content: "\f15b";
    padding-right: .3em;
}
.gnzdia.formContainer.baseFormat-xls > h3::before {
    content: "\f1c3";
}
.gnzdia.formContainer.baseFormat-doc > h3::before {
    content: "\f1c2";
}
*/


/* BUTTONS */
.gnzdia .buttons input[type="submit"] {
    font-size: 1.2em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: inline-block;
    color: rgba(255,255,255,.9);
    text-shadow: #2e7ebd 0 .1em .2em;
    text-decoration: none;
    text-align: center;
    line-height: 1.1;
    white-space: pre-line;
    padding: .27em .7em .23em .7em;
    border: thin solid;
    border-color: #60a3d8 #2970a9 #2970a9 #60a3d8;
    border-radius: .17em;
    outline: none;
    background: #60a3d8 linear-gradient(#89bbe2, #60a3d8 50%, #378bce);
    box-shadow: inset rgba(255,255,255,.5) .1em .1em;
    transition: .3s;
}
.gnzdia .buttons input[type="submit"]:hover{
    color: rgb(255,255,255);
    background-image: linear-gradient(#9dc7e7, #74afdd 50%, #378bce);
    box-shadow: 0 0 .4em #74afdd;
}
.gnzdia .buttons input[type="submit"]:focus {
    color: rgb(255,255,255);
    background-image: linear-gradient(#9dc7e7, #74afdd 50%, #378bce);
    box-shadow: 0 0 .6em #378bce;
}
.gnzdia .buttons input[type="submit"]:active {
    color: rgb(255,255,255);
    border-color: #2970a9;
    background-image: linear-gradient(#5796c8, #6aa2ce);
    box-shadow: none;
}
.gnzdia .buttons input[type="submit"][disabled]:active {
    background: #8fb5db;
    text-shadow: none;
    border: thin solid #8fb5db;
    box-shadow: .01em .01em .1em #345;
}


.gnzdia input, .gnzdia select {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;

    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
}

.gnzdia input:hover, .gnzdia select:hover {
    border-color: #4096ff;
    background-color: #ffffff;
}
