:root {
    --content-padding: 1em;
}

#app, #app > *, #app > * > * {
    height: 100%;
}

#main {
    padding: var( --content-padding );
    height: 100%;
    overflow: auto;
}

#content {
    height: 100%;
    overflow: auto;
}

button.add > * { color: darkolivegreen}
button.upd > * { color: darkslateblue}
button.del > * { color: firebrick}